import React from "react";
import {Flex, Text, Heading, Stack} from "@chakra-ui/core";
import CompassIcon from "@pency/components/icons/Compass";
import {Global, css} from "@emotion/core";
import Head from "next/head";

import {META} from "../constants";

interface Props {
  message: string;
  heading: string
}

const MaintainanceScreen: React.FC<Props> = ({message, heading}) => {
  return (
    <>
      <Global
        styles={css`
          * {
            touch-action: manipulation;
            font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
              "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
          }
        `}
      />
      <Head>
        <link href={META.favicon} rel="icon" />
        <link href={META.appleicon} rel="apple-touch-icon" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&display=swap"
          rel="stylesheet"
        />
        <title>{`Oops! | ${META.title}`}</title>
        <meta content={META.theme} name="theme-color" />
        <meta content={META.description} name="description" />
        <meta content={META.keywords} name="keywords" />
        <meta content={META.author} name="author" />
        <meta content={META.fbapp} property="fb:app_id" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content={META.twitter} name="twitter:site" />
        <meta content={META.twitter} name="twitter:creator" />
        <meta content={META.url} property="og:url" />
        <meta content="website" property="og:type" />
        <meta content={META.title} property="og:title" />
        <meta content={META.description} property="og:description" />
        <meta content={META.banner?.url} property="og:image" />
        <meta content={META.banner?.url} property="og:image:url" />
        <meta content={META.banner?.format} property="og:image:type" />
        <meta content={META.banner?.width} property="og:image:width" />
        <meta content={META.banner?.height} property="og:image:height" />
        <meta content={META.title} property="og:image:alt" />
      </Head>
      <Flex
        alignItems="center"
        backgroundColor="gray.100"
        color="white"
        height="100vh"
        justifyContent="center"
        overflow="hidden"
        padding={4}
        textAlign="center"
        width="100vw"
      >
        <Stack alignItems="center">
          <CompassIcon color="teal.500" fontSize="xl" marginBottom={2} size={36} />
          <Heading as="h4" color="black" fontWeight="bold" size="lg">
            {heading}
          </Heading>
          <Text color="gray.600" fontSize="lg">
            {message}
          </Text>
        </Stack>
      </Flex>
    </>
  );
};

export default MaintainanceScreen;
