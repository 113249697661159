import Router from "next/router";
import React from "react";
import ThemeProvider from "@pency/components/structure/ThemeProvider";
import Head from "next/head";
import {Global, css} from "@emotion/core";
import {appWithTranslation} from "next-i18next";
import 'react-lazy-load-image-component/src/effects/blur.css';
import {GTMPageView} from "../utils/gtm";
import {META} from "../constants";

import analytics from "~/analytics/api";
import MaintainanceScreen from "./_maintainance";

function App({Component, pageProps}) {
  React.useEffect(() => {
    process.browser && analytics.pageview(window.location.pathname + window.location.search);
    const handleRouteChange = (url: string) => GTMPageView(url);
    Router.events.on("routeChangeComplete", handleRouteChange);
  //   if (window.location.pathname === '/terminos-y-condiciones') {
  //      require('../ui/styles/terms.css')
  //   }
  //   if (window.location.pathname === '/politica-de-privacidad') {
  //     require('../ui/styles/privacy.css')
  //  }

    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);
  const isMaintainance = process.env.NEXT_PUBLIC_MAINTENANCE === "MAINTENANCE";

  return (
    <ThemeProvider>
      {isMaintainance ? <MaintainanceScreen message="Página en mantenimiento" heading="Pency" /> : (
      <>
      <Global
        styles={css`
          * {
            touch-action: manipulation;
            font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
              "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
            font-feature-settings: "cv02", "cv03", "cv04", "cv11";
          }
          @font-face {
            font-family: "Recoleta-Bold";
            src: url("webFonts/recoleta-bold.woff2") format("woff2"),
              url("webFonts/recoleta-bold.woff") format("woff");
            font-display: swap;
          }
        `}
      />
      <Head>
        <link
          as="font"
          crossOrigin="anonymous"
          href="webFonts/recoleta-bold.woff2"
          rel="preload"
          type="font/woff2"
        />
        <link href={META.favicon} rel="icon" />
        <link href={META.appleicon} rel="apple-touch-icon" />
        <link href={META.url} rel="canonical" />
        <link href="https://fonts.gstatic.com" rel="preconnect" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
        <title>{META.title}</title>
        <meta content={META.theme} name="theme-color" />
        <meta content={META.description} name="description" />
        <meta content={META.keywords} name="keywords" />
        <meta content={META.author} name="author" />
        <meta content={META.fbapp} property="fb:app_id" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content={META.twitter} name="twitter:site" />
        <meta content={META.twitter} name="twitter:creator" />
        <meta content={META.author} property="og:site_name" />
        <meta content={META.url} property="og:url" />
        <meta content="website" property="og:type" />
        <meta content={META.banner?.url} property="og:image" />
        <meta content={META.banner?.format} property="og:image:type" />
        <meta content={META.banner?.width} property="og:image:width" />
        <meta content={META.banner?.height} property="og:image:height" />
        <meta content={META.title} property="og:image:alt" />
        <meta name="facebook-domain-verification" content="qr91qs7ttwtilruts16nwoepsavzvz" />
      </Head>
      <Component {...pageProps} />
      </>
    )}
    </ThemeProvider>
  );
}

export default appWithTranslation(App);
