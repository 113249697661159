export const META = {
  favicon: "/favicon.ico",
  theme: "#FFFFFF",
  fbapp: "236717644287641",
  title: "Pency - Comienza a vender online gratis",
  description:
    "Vende en tu propia tienda online o haz el menú digital de tu comercio. Exhibe todos tus productos y recibe los pedidos en tu WhatsApp.",
  appleicon: "/logo192.jpg",
  keywords: "tienda, online, fácil, delivery, compra, venta, menu qr, carta digital, vender online",
  author: "Pency",
  twitter: "@pencyapp",
  url: "https://web.pency.app",
  banner: {
    url: "/og-image.jpg",
    format: "image/jpeg",
    width: "1200",
    height: "630",
  },
};
